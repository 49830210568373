<template>
  <div class="reserveHome">
    <ul  class="title">
      <li  @click="tebyyzt(1)" :class="yyidx==1?'xzyy':''">待预约</li>
      <li @click="tebyyzt(2)" :class="yyidx==2?'xzyy':''">已预约</li>
      <li @click="tebyyzt(3)" :class="yyidx==3?'xzyy':''">预约今日</li>
      <li @click="tebyyzt(4)" :class="yyidx==4?'xzyy':''">手术中</li>
      <li @click="tebyyzt(5)" :class="yyidx==5?'xzyy':''">手术后</li>
    </ul>
    <ul class="fn">
      <li>
        <p>就诊时间：</p>
        <el-date-picker
            size="small"
            class="time"
            v-model="time"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份">
        </el-date-picker>
      </li>
      <li>
        <el-input class="srid"  size="small" v-model="input" placeholder="请输入患者ID或者姓名"></el-input>
      </li>
      <li>
        <span>手术：</span>
        <el-cascader style="width: 280px; margin-right: 20px;" :options="options" v-model="sxshlx"  laceholder="请选择" :show-all-levels="true"></el-cascader>
      </li>
      <li> <el-button size="small" type="primary" @click="getyylist()">查询</el-button></li>
    </ul>

    <ul v-if="yyidx!=2&&yyidx!=3" class="bt">
      <li>序号</li>
      <li>患者信息</li>
      <li>手机号</li>
      <li>就诊医生</li>
      <li>手术时间</li>
      <li>眼别</li>
      <li>手术</li>
      <li>操作</li>
    </ul>
    <div v-if="yyidx!=2&&yyidx!=3" class="box">
      <ul v-for="(item, idx) in yjyyarr" class="tbcontent">
        <li>{{idx+1}}</li>
        <li>
          <p>{{item.CustomerName}}|{{item.CustomerGender=='1'?'男':'女'}}</p>
          <p>{{item.CustomerBirthday.split(' ')[0]}}</p>
        </li>
        <li>{{item.CustomerTel}}</li>
        <li>{{item.ExpertName}}</li>
        <li>{{item.OADate}}</li>
        <li>{{getODU(item)}}</li>
        <li class="ssys">
          <p v-if="item.OAODOperationName">
            <span>OD</span>
            <span>{{item.OAODOperationName}}</span>
          </p>
          <p v-if="item.OAOSOperationName">
            <span>OS</span>
            <span>{{item.OAOSOperationName}}</span>
          </p>
          <p v-if="item.OAOUOperationName">
            <span>OU</span>
            <span>{{item.OAOUOperationName}}</span>
          </p>
        </li>
        <li class="cz">
          <span v-if="yyidx==1" @click="opyyss(1, item.OAChooseId, item.OACustomerId)">预约</span>
          <span v-if="yyidx!=1 && yyidx!=5"  @click="opyyss(yyidx, item.OAChooseId, item.OACustomerId)">修改</span>
          <span v-if="yyidx == 2" @click="deleyy(item.OAChooseId)">取消</span>
          <span @click="gogb(item.OAChooseId, item.OACustomerId)">门诊病历</span>
          <span v-if="yyidx==5" @click="goxq(1, item)">手术病例</span>
        </li>
      </ul>
    </div>

    <ul v-if="yyidx==2||yyidx==3" class="ybt">
      <li>序号</li>
      <li>患者信息</li>
      <li>手机号</li>
      <li>就诊医生</li>
      <li>手术时间</li>
      <li>眼别</li>
      <li>手术</li>
      <li>报到</li>
      <li>操作</li>
    </ul>
    <div v-if="yyidx==2||yyidx==3" class="ybox">
      <ul v-for="(item, idx) in yjyyarr" class="tbcontent">
        <li>{{idx+1}}</li>
        <li>
          <p>{{item.CustomerName}}|{{item.CustomerGender=='1'?'男':'女'}}</p>
          <p>{{item.CustomerBirthday.split(' ')[0]}}</p>
        </li>
        <li>{{item.CustomerTel}}</li>
        <li>{{item.ExpertName}}</li>
        <li>{{item.OADate}}</li>
        <li>{{getODU(item)}}</li>
        <li class="ssys">
          <p v-if="item.OAODOperationName">
            <span>OD</span>
            <span>{{item.OAODOperationName}}</span>
          </p>
          <p v-if="item.OAOSOperationName">
            <span>OS</span>
            <span>{{item.OAOSOperationName}}</span>
          </p>
          <p v-if="item.OAOUOperationName">
            <span>OU</span>
            <span>{{item.OAOUOperationName}}</span>
          </p>
        </li>
        <li>
          <el-switch
              @change="isbdp($event, item)"
              v-model="item.OAIsCheckin"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </li>
        <li class="cz">
          <span v-if="yyidx==2 || yyidx==3"  @click="opyyss(2, item.OAChooseId, item.OACustomerId)">修改</span>
          <el-dropdown @command="goxq($event, item)">
            <span class="el-dropdown-link" style="font-size: 15px;color: #0943ff;">
              详情
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">术前病历</el-dropdown-item>
              <el-dropdown-item command="2">术前核查</el-dropdown-item>
              <el-dropdown-item command="3">手术登记</el-dropdown-item>
              <el-dropdown-item command="4">安全核查</el-dropdown-item>
              <el-dropdown-item command="5">护理记录</el-dropdown-item>
              <el-dropdown-item command="6">手术记录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span style="padding-right: 10px;" v-if="yyidx == 2|| yyidx==3" @click="deleyy(item.OAChooseId)">取消</span>
          <span @click="gogb(item.OAChooseId, item.OACustomerId)">病历</span>
        </li>
      </ul>
    </div>


    <div class="mg" v-if="showYYss">
      <SSYY :userData="itemUserData" @close="close"></SSYY>
    </div>
  </div>
</template>

<script>
/*

* */
import pf from "../../publicFn/baseFn";
import time from "../../store/moddules/time";


export default {
  name: "reserveHome",
  data() {
    return {
      time: [pf.getTime(-30), pf.getTime(0),],
      input: '',
      yyidx: 1,
      yjyyarr: [],
      showYYss: false,
      isxxtype: 1,
      itemUserData: '',
      isbd: false,

      sxshlx: '',
      options: [
        {
          value: '晶体植入手术',
          label: '晶体植入手术',
          children: [
            {
              value: 'PRL',
              label: 'PRL'
            },
            {
              value: 'ICL',
              label: 'ICL'
            },
            {
              value: 'TICL',
              label: 'TICL'
            }
          ]
        },
        {
          value: '角膜激光手术',
          label: '角膜激光手术',
          children: [
            {
              value: '德国鹰视PTK',
              label: '德国鹰视PTK'
            },
            {
              value: '精雕飞秒+睛春clear+Q',
              label: '精雕飞秒+睛春clear+Q'
            },
            {
              value: '普通飞秒激光',
              label: '普通飞秒激光'
            },

            {
              value: '超薄flap+lasik+Q',
              label: '超薄flap+lasik+Q'
            },

            {
              value: '超薄普通飞秒',
              label: '超薄普通飞秒'
            },
            {
              value: '二次增效手术',
              label: '二次增效手术'
            },
            {
              value: '精雕飞秒+Q',
              label: '精雕飞秒+Q'
            },
            {
              value: '蔡司全飞秒SMILE（薄帽）',
              label: '蔡司全飞秒SMILE（薄帽）'
            },
            {
              value: '德国鹰视PTK+Q传统LASEK',
              label: '德国鹰视PTK+Q传统LASEK'
            },
            {
              value: '蔡司全飞秒SMILE',
              label: '蔡司全飞秒SMILE'
            },
            {
              value: '精雕飞秒+睛春clear',
              label: '精雕飞秒+睛春clear'
            },
            {
              value: '全飞秒+散光自旋补偿',
              label: '全飞秒+散光自旋补偿'
            }
          ]
        },
        {
          value: '白内障手术',
          label: '白内障手术',
          children: [
            {
              value: 'PHACO+IOL植入术',
              label: 'PHACO+IOL植入术'
            }
          ]
        },
        {
          value: '',
          label: '全部'
        },
      ],
    }
  },
  computed: {

  },
  created() {
    // this.getyylist()
    this.getyylist()
  },

  methods: {
    close() {
      this.showYYss = false
    },

    gogb(id, csid) {
      this.$router.push({path: 'Iframe?type=0&csId='+id+'&cusid='+csid})
    },

    opyyss(type, chooid, csid) {
      this.isxxtype = type
      this.itemUserData = {
        chooid: chooid,
        csid: csid,
        type: type
      }
      this.showYYss = true
    },

    tebyyzt(idx) {
      this.yyidx = idx
      this.getyylist()
    },

    deleyy(id) {
      // console.log(ite)
      // console.log(id)
      this.$confirm('确定取消？')
          .then(_ => {
            let log = this.$loading({
              text:'加载中...'
            })
            this._api.expertClinic.OADeleteById(id)
                .then(res => {
                  // console.log(res)
                  log.close()
                  this.getyylist()
                  this.$alert(res.displaymsg, '提示')
                })
          })
          .catch(_ => {})

    },

    getyylist() {
      let zt = '',
          time = this.time
      switch (this.yyidx){
        case 1:
          zt = 'pre'
          time = ['', '']
          break
        case 2:
          zt = 'expected'
          break
        case 3:
          zt = 'expected'
          time = [pf.getTime(0), pf.getTime(0)]
          break
        case 4:
          zt = 'doing'
          break
        case 5:
          zt = 'finish'
          break
      }

      let log = this.$loading({
        text:'加载中...'
      })

      // if (this.yyidx < 2) {
      //   this._api.expertClinic.OAGetListPre(time, zt)
      //       .then(res => {
      //         // console.log(res)
      //         log.close()
      //         if (res.Result && res.Result.length) {
      //           this.yjyyarr = [...res.Result]
      //         }else {
      //           this.yjyyarr = []
      //         }
      //       })
      //   return
      // }

      let ssnx = ''
      if (typeof this.sxshlx == 'object') {
        ssnx = this.sxshlx&&this.sxshlx.length?this.sxshlx[1]:''
      }
      this._api.expertClinic.OAGetListPre(time, zt, ssnx)
          .then(res => {
            // console.log(res)
            log.close()
            if (res.Result && res.Result.length) {
              this.yjyyarr = [...res.Result]
            }else {
              this.yjyyarr = []
            }
          })

    },

    getODU(item) {
      if (item.OAOUOperationProductId) {
        return 'OU'
      }
      if (item.OAODOperationProductId && !item.OAOSOperationProductId) {
        return 'OD'
      }
      if (!item.OAODOperationProductId && item.OAOSOperationProductId) {
        return 'OS'
      }
      if (item.OAODOperationProductId && item.OAOSOperationProductId) {
        return 'OU'
      }
      return 'OU'
    },

    isbdp(e, data) {
      // console.log(e)
      // data.OAIsCheckin = e
      let log = this.$loading({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
      })
      this._http.post(this._getUrl('OA_Update'), {
        "token": {
          "TimeSpan": 1616479500,
          "sToken": "4D6A372AC044AC7A41331B9141F46536"
        },
        o: data
      })
      .then(res => {
        log.close()
        if (res.ReturnCode === 5000) {
          this.$alert('修改成功！', '提示')
        }else {
          this.$alert('修改失败！请稍后再试', '提示')
        }
      })
    },

    goxq(e, ysdata) {
      let url = '',item = 1
      if (ysdata.OAType == '白内障手术') item = 2
      if (ysdata.OAType == '屈光晶体手术') item = 3
      switch (Number(e)) {
        case 1:
          url = `http://soperation.visumall.com/#/medical-record?chooseId=${ysdata.OAChooseId}&expertId=${ysdata.OAAddExpertId}&type=${item}`
          break
        case 2:
          url = `http://soperation.visumall.com/#/preoperative-check?chooseId=${ysdata.OAChooseId}&expertId=${ysdata.OAAddExpertId}&type=${item}`
              break
        case 3:
          url = `http://soperation.visumall.com/#/surgical-registration?chooseId=${ysdata.OAChooseId}&expertId=${ysdata.OAAddExpertId}&type=${item}`
          break
        case 4:
          url = `http://soperation.visumall.com/#/security-check?chooseId=${ysdata.OAChooseId}&expertId=${ysdata.OAAddExpertId}&type=${item}`
          break
        case 5:
          url = `http://soperation.visumall.com/#/nursing-record?chooseId=${ysdata.OAChooseId}&expertId=${ysdata.OAAddExpertId}&type=${item}`
          break
        case 6:
          url = `http://soperation.visumall.com/#/surgical-records?chooseId=${ysdata.OAChooseId}&expertId=${ysdata.OAAddExpertId}&type=${item}`
          break
      }
      // window.open(url)
      this.$router.push({path: '/ifame3', query: {url: url}})
    }
  },
  components: {
    SSYY: () => import('./surgeryAppointment')
  }
}
</script>


<style scoped lang="scss">
  .reserveHome {
    width: 100vw;
    height: 86vh;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 15px;
    li {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      color: #333;
      width: 132px;
      text-align: center;
      transition: none;
      cursor: pointer;
      border: 1px solid #d9d9d9;
      &:hover {
        border-color: #557afc;
        box-shadow: none !important;
        background: #e5e5fd;
      }
    }
    .xzyy {
      border-color: #557afc;
      box-shadow: none !important;
      background: #e5e5fd;
    }
  }
  .fn {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 10px;
    li {
      display: flex;
      align-items: center;
      .time {
        width: 230px;
      }
      .srid {
        width: 170px;
        margin: 0 13px;
      }
    }
  }
  .bt {
    background-color: #f5f8fd;
    border-color: #d7d7d7;
    border-width: 1px 0 1px 0;
    border-style: solid;
    color: #666;
    display: flex;
    padding: 8px 0;
    li:nth-child(1){width: 5%;}
    li:nth-child(2){width: 15%;}
    li:nth-child(3){width: 15%;}
    li:nth-child(4){width: 10%;}
    li:nth-child(5){width: 10%;}
    li:nth-child(6){width: 5%;}
    li:nth-child(7){width: 25%;text-align: left;}
    li:nth-child(8){width: 15%;}
  }
  .box {
    width: 100vw;
    height: calc(100% - 139px);
    overflow-y: auto;
    .tbcontent {
      display: flex;
      align-items: center;
      li {padding: 5px 0;}
      li:nth-child(1){width: 5%;}
      li:nth-child(2){width: 15%;}
      li:nth-child(3){width: 15%;}
      li:nth-child(4){width: 10%;}
      li:nth-child(5){width: 10%;}
      li:nth-child(6){width: 5%;}
      li:nth-child(7){width: 25%;text-align: left;}
      li:nth-child(8){width: 15%;}
      .ssys {
        p:nth-child(1) {padding-bottom: 5px;}
        span:nth-child(1){
          background-color: #1d35a1;
          color: #fff;
          text-align: center;
          width: 32px;
          display: inline-block;
          font-size: 12px;
          border: 1px solid #1d35a1;
          vertical-align: middle;
          height: 20px;
          line-height: 20px;
        }
        span:nth-child(2) {
          border: 1px solid #3d8af8;
          background-color: #e5e5fd;
          color: #333;
          padding: 0 4px;
          font-size: 12px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
          margin-right: 4px;
          min-width: 100px;
          height: 20px;
          line-height: 20px;
        }
      }
      .cz {
        color: #0943ff;
        span {cursor: pointer;}
        span:nth-child(1) {padding-right: 10px;}
        span:nth-child(2) {padding-right: 10px;}
      }
      &:hover {background: #eaeaea;}
    }
  }

  .ybt {
    background-color: #f5f8fd;
    border-color: #d7d7d7;
    border-width: 1px 0 1px 0;
    border-style: solid;
    color: #666;
    display: flex;
    padding: 8px 0;
    li:nth-child(1){width: 5%;}
    li:nth-child(2){width: 12%;}
    li:nth-child(3){width: 12%;}
    li:nth-child(4){width: 10%;}
    li:nth-child(5){width: 10%;}
    li:nth-child(6){width: 5%;}
    li:nth-child(7){width: 25%;text-align: left;}
    li:nth-child(8){width: 5%;}
    li:nth-child(9){width: 16%;}
  }
  .ybox {
    width: 100vw;
    height: calc(100% - 139px);
    overflow-y: auto;
    .tbcontent {
      display: flex;
      align-items: center;
      li {padding: 5px 0;}
      li:nth-child(1){width: 5%;}
      li:nth-child(2){width: 12%;}
      li:nth-child(3){width: 12%;}
      li:nth-child(4){width: 10%;}
      li:nth-child(5){width: 10%;}
      li:nth-child(6){width: 5%;}
      li:nth-child(7){width: 25%;text-align: left;}
      li:nth-child(8){width: 5%;}
      li:nth-child(9){width: 16%;}
      .ssys {
        p:nth-child(1) {padding-bottom: 5px;}
        span:nth-child(1){
          background-color: #1d35a1;
          color: #fff;
          text-align: center;
          width: 32px;
          display: inline-block;
          font-size: 12px;
          border: 1px solid #1d35a1;
          vertical-align: middle;
          height: 20px;
          line-height: 20px;
        }
        span:nth-child(2) {
          border: 1px solid #3d8af8;
          background-color: #e5e5fd;
          color: #333;
          padding: 0 4px;
          font-size: 12px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
          margin-right: 4px;
          min-width: 100px;
          height: 20px;
          line-height: 20px;
        }
      }
      .cz {
        color: #0943ff;
        span {cursor: pointer;}
        span:nth-child(1) {padding-right: 10px;}
        span:nth-child(2) {padding-right: 10px;}
      }
      &:hover {background: #eaeaea;}
    }
  }

  .mg {
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
